<template>
  <div class="weixing_pop_up">
    <!-- {{$store.state.weixingHome.moreFlag}} -->
    <div :class="[{'size': $store.state.weixingHome.moreFlag}]" class="pop_up">
      <div class="close_btn_wrap">
        <WeixingTitleTwo :txt="'产业发展'"></WeixingTitleTwo>
        <div @click="closeBtn" class="close_btn"></div>
      </div>
      <div class="cont_box">
        <ul class="cont_boxList">
          <li
             v-for="(item, index) in $store.state.weixingHome.industryList"
            :key="index"
          >
            <WeixingTitleTwo
              :txt="item.title"
              :type="2"
              ></WeixingTitleTwo>
            <p>{{item.txt}}</p>
          </li>
        </ul>
        <!-- <h4>{{$store.state.zhongguancunIndustry.popUpCont.title}}</h4>
        <span>产业方向:{{$store.state.zhongguancunIndustry.popUpCont.type}}</span>
        <p>{{$store.state.zhongguancunIndustry.popUpCont.txt}}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
  import WeixingTitleTwo from '@/components/WeixingTitleTwo.vue'
  export default {
    methods: {
      closeBtn() {
        this.$store.state.zhongguancunIndustry.popUpCont = ''
        this.$store.state.weixingHome.moreFlag = false
      }
    },
    components: {
      WeixingTitleTwo
    }
  }
</script>

<style lang="less">
.weixing_pop_up{
  .size{
    transform: scale(1) !important;
  }
  .noneAmin{
    transform: scale(1) !important;
    transition: 0s !important;
  }
  position: relative;
  .pop_up{
    transform: scale(0);
    transition: .5s;
    position: absolute;
    top: 256px;
    left: 596px;
    width: 728px;
    height: 471px;
    border-radius: 20px;
    // border: 1px solid #4dddac;
    // background: rgba(23, 68, 53, .2);
    z-index: 10;
    background: url(../../assets/images/weixingMainBg.png) 100% 100%/cover no-repeat;
  }
  .close_btn_wrap{
    width: 100%;
    padding: 0 13px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .close_btn{
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-right: 14px;
      margin-top: 16px;
      background: url(../../assets/images/wexingClose.png) no-repeat;
    }
    .title_cont{
      font-size: 20px;
      margin-top: 38px;
    }
  }
  .cont_box{
    .title_cont{
      margin-top: 10px;
    }
    li{
      padding: 0 13px;
      margin-top: 7px;
    }
    h4, span{
      text-align: center;
      display: block;
    }
    h4{
      font-size: 20px;
      font-weight: 400;
    }
    span{
      color: #43e8b6;
      margin-top: 3px;
    }
    p{
      padding: 0 33px;
      line-height: 22px;
      text-align: justify;
    }
    // span{
    //   text-align: center;
    // }
  }
}
</style>