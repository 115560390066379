<template>
  <div class="title_wrap">
      <div class="title_left">
        <img :src="cover" alt="">
      </div>
      <div class="title_cont">
        {{txt}}
      </div>
  </div>
</template>
<script>
  export default {
    props: {
      cover: {
        default: '',
        type: String
      },
      txt: {
        default: '',
        type: String
      }
    },
  }
</script>
<style lang="less" scoped>
  .title_wrap{
    display: flex;
    padding-left: 10px;
    // border-left:1px solid #99ccff;
    .title_left{
      width: 24px;
      height: 24px;
      // background-image: linear-gradient(to right, #021b67, #04091f);
      // position: relative;
      // z-index: -1;
    }
    .title_cont{
      width: 324px;
      padding-left: 6px;
      text-align: left;
      font-size: 20px;
      text-shadow: 1px 0px 1px #3898ec,-1px 0px 1px #3898ec,0px 1px 1px #3898ec,0px -1px 1px #3898ec;
    }
  }
</style>