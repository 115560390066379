import { render, staticRenderFns } from "./LeftContent.vue?vue&type=template&id=386f1365&scoped=true&"
import script from "./LeftContent.vue?vue&type=script&lang=js&"
export * from "./LeftContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386f1365",
  null
  
)

export default component.exports