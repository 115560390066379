<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2023-06-27 21:38:02
 * @Description: 请您输入文件描述
-->
<template>
	<div class="weixing_left_content">
		<div class="top">
			<WexinTitle :cover="require('@/assets/images/weixingTitleIcon2.png')" :txt="'园区介绍'" :type="2"></WexinTitle>
			<p class="introduce">北京卫星制造厂旧址位于北京·海淀区创业大街——知春路，是北京乃至中国科技、教育、文化资源最为丰富的区域。项目以中关村大街、学院路为轴线，是中关村科学城核心区，也是中关村国家自主创新示范区核心区的核心。北京卫星制造厂是我国东方红一号卫星诞生地，也是我国多个卫星、返回舱诞生地，具有丰富的历史背景。</p>
		</div>
		<div class="bottom">
			<WexinTitle :cover="require('@/assets/images/weixingTitleIcon1.png')" :txt="'园区规划'"></WexinTitle>
			<!-- <h3 class="area_title">项目总建筑面积（㎡）</h3> -->
			<WeixingTitleTwo :txt="'项目总建筑面积（㎡）'"></WeixingTitleTwo>
			<div class="area_txt">
				<div v-for="(item, index) in areaInfo" :key="index">
					<ICountUp v-if="item != '.'" class="num" :delay="$store.state.overView.ICountUpOption.delay" :endVal="parseInt(item)" :options="{ separator: $store.state.overView.ICountUpOption.separator }" />
					<span class="spot" v-if="item == '.'">{{ item }}</span>
				</div>
				<!-- <span v-for="(item, index) in areaInfo" :key="index">{{item}}</span> -->
			</div>
			<ul class="area_list">
				<li v-for="(item, index) in measureArea" :key="index">
					<div class="area_list_title">
						<!-- <h4>{{item.title}}</h4> -->
						<!-- <img :src="item.cover" alt=""> -->
						<HomeIcon :width="45" :height="45" :absoluteValue="47" :src="item.cover" :color="item.color"></HomeIcon>
					</div>
					<div class="area_list_box">
						<span>{{ item.title }}建筑面积：</span>
						<div class="area_list_num">
							<ICountUp class="num" :style="[{ color: item.color }]" :delay="$store.state.overView.ICountUpOption.delay" :endVal="item.area" :options="{ separator: $store.state.overView.ICountUpOption.separator }" />
							<!-- <p :style="[{color: item.color}]">{{item.area}}</p> -->
							<div class="company_wrap">
								<span class="company">(m²)</span>
							</div>
							<!-- <div class="area_list_border"></div> -->
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import WexinTitle from '@/components/WeixingTitle.vue';
	import WeixingTitleTwo from '@/components/WeixingTitleTwo.vue';
	import HomeIcon from '@/components/HomeIcon.vue';
	import ICountUp from 'vue-countup-v2';

	export default {
		data() {
			return {
				areaInfo: '33409.2',
				measureArea: [
					{
						title: '1#厂房',
						cover: require('@/assets/images/weixingIcon1.png'),
						area: 17773.71,
						color: '#1a78ca',
					},
					{
						title: '2#厂房',
						cover: require('@/assets/images/weixingIcon2.png'),
						area: 9180.04,
						color: '#2dc8c8',
					},
					{
						title: '3#厂房',
						cover: require('@/assets/images/weixingIcon3.png'),
						area: 3741.01,
						color: '#24b1c8',
					},
					{
						title: '5#厂房',
						cover: require('@/assets/images/weixingIcon4.png'),
						area: 1315.17,
						color: '#c4bc66',
					},
					{
						title: '行政楼',
						cover: require('@/assets/images/weixingIcon5.png'),
						area: 761.25,
						color: '#25b49a',
					},
				],
			};
		},
		components: {
			WexinTitle,
			WeixingTitleTwo,
			HomeIcon,
			ICountUp,
		},
	};
</script>

<style scoped></style>
