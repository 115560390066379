<template>
  <div class="title_wrap">
      <p :class="[{txtType1: type == 1}, {txtType2: type == 2}]" class="title_cont">
        {{txt}}
      </p>
  </div>
</template>
<script>
  export default {
    props: {
      txt: {
        default: '',
        type: String
      },
      type: {
        default: 1
      }
    },
  }
</script>
<style lang="less" scoped>
  .title_wrap{
    display: flex;
    padding-left: 20px;
    .title_left{
      width: 24px;
      height: 24px;
    }
    .title_cont{
      position: relative;
      &::before{
        }
      width: 324px;
      padding-left: 6px;
      text-align: left;
      margin-top: 24px;
      font-size: 16px;
      // position: relative;
    }
    .txtType1::before{
      content: '';
      position: absolute;
      top: 7px;
      left: -12px;
      width: 7px;
      height: 7px;
      background-color: #2ca6ff;
      border-radius: 50%;
    }
    .txtType2::before{
      content: '';
      position: absolute;
      top: 7px;
      left: -12px;
      width: 5px;
      height: 5px;
      // background-color: #2ca6ff;
      border: 2px solid #2ca6ff;
      border-radius: 50%;
    }
    
  }
</style>