<template>
    <div class="IntroduceEcharts_wrap">
      <div class="IntroduceEcharts_bg"></div>
      <!-- {{$store.state.weixingHome.allAtlas}} -->
      <div id="IntroduceEcharts" :style="{width: '100%', height: '295px'}"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts'

    export default {
        data () {
            return {

            }
        },
        mounted () {
            // this.drawLine();
            this.drawLine()
        },
        methods: {
            drawLine(){
                // 基于准备好的dom，初始化echarts实例
                // let that = this
                let IntroduceEcharts = this.$echarts.init(document.getElementById('IntroduceEcharts'))
                // 绘制图表
                IntroduceEcharts.setOption({
                  legend: {
                    show: false,
                    orient: 'vertical',
                    x: 'right',
                    y: 'center',
                    align: 'left',
                    padding:[0,0,0,0],
                    itemHeight: 0,
                    itemWidth: 0,
                    // textStyle: {
                    //   color: 'rgba(0,0,0,0)',
                    // },
                    // formatter: {
                    //   show: true
                    // }
                    // legend: {
                    //   show: false
                    // },
                    // formatter: function(industry_type) {
                    //   var index = 0;
                    //   var clientlabels = that.$store.state.home.allAtlas;
                    //   var clientcounts = that.$store.state.home.allAtlas;
                    //   clientlabels.forEach(function(value,i){
                    //       if(value.industry_type == industry_type){
                    //           index = i;
                    //       }
                    //   });
                    //   return industry_type + "  " + '(' + clientcounts[index].value + ')';
                    // }
                  },
                  // color: [
                  //       '#2196fb',
                  //       '#31fffe',
                  //       '#25c4f2',
                  //       '#27e3b0',
                  //       '#f4e456',
                  //       '#e3ac27',
                  // ],
                  color: [
                    new echarts.graphic.LinearGradient(1,1,0,0, [{
                        offset:0.4,
                        color: '#2196fb'
                    },
                    {
                        offset: 0.8,
                        color: 'rgba(33,150,251,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'transparent'
                    }]),
                    new echarts.graphic.LinearGradient(1,1,0,0, [{
                        offset:0.4,
                        color: '#31fffe'
                    },
                    {
                        offset: 0.8,
                        color: 'rgba(49,255,255,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'transparent'
                    }]),
                    new echarts.graphic.LinearGradient(0,1,1,0, [{
                        offset:0.4,
                        color: '#25c4f2'
                    },
                    {
                        offset: 0.8,
                        color: 'rgba(37,196,242,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'transparent'
                    }]),
                    new echarts.graphic.LinearGradient(0,0,1,1, [{
                        offset:0.4,
                        color: '#27e3b0'
                    },
                    {
                        offset: 0.8,
                        color: 'rgba(39,227,176,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'transparent'
                    }]),
                    new echarts.graphic.LinearGradient(0,0,1,1, [{
                        offset:0.4,
                        color: '#f4e456'
                    },
                    {
                        offset: 0.8,
                        color: 'rgba(244,228,86,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'transparent'
                    }]),
                    new echarts.graphic.LinearGradient(1,0,0,1, [{
                        offset:0.4,
                        color: '#e3ac27'
                    },
                    {
                        offset: 0.8,
                        color: 'rgba(227,172,39,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'transparent'
                    }]),
                  ],
                  series: [
                    {
                      
                      name: '',
                      type: 'pie',
                      // radius: '55%',
                      radius: ['60%', '75%'],
                      center: ['48%', '50%'],
                      data: this.$store.state.weixingHome.allAtlas,
                      /* data: [
                        { value: this.$store.state.home.allAtlas[0].company_num, name: this.$store.state.home.allAtlas[0].industry_type },
                        { value: this.$store.state.home.allAtlas[1].company_num, name: this.$store.state.home.allAtlas[1].industry_type },
                        { value: this.$store.state.home.allAtlas[2].company_num, name: this.$store.state.home.allAtlas[2].industry_type },
                        { value: this.$store.state.home.allAtlas[3].company_num, name: this.$store.state.home.allAtlas[3].industry_type },
                        { value: this.$store.state.home.allAtlas[4].company_num, name: this.$store.state.home.allAtlas[4].industry_type },
                        // { value: this.$store.state.home.allAtlas[5].company_num, name: this.$store.state.home.allAtlas[5].industry_type },
                        
                        // 医药制造（7）；生物技术（8）、医疗器械（2）、数字健康（1）、材料（1）、化工（1）
                      ], */
                      //roseType: 'radius',
                      label: {
                        normal: {
                          textStyle: {
                              color: '#fff',
                          },
                          formatter: `{b}`
                        },
                      },
                      labelLine: {
                        smooth: 0.2,
                        length: 10,
                        length2: 20
                      },
                       
                      animationType: 'scale',
                      animationEasing: 'elasticOut',
                      animationDelay: function () {
                        return Math.random() * 200;
                      }
                    },
                  ]
                });
            },
        }
    }
</script>
<style lang="less">
  .IntroduceEcharts_wrap{
    position: relative;
    .IntroduceEcharts_bg{
      position: absolute;
      top: 80px;
      left: 120px;
      width: 200px;
      height: 200px;
      background: url(../../assets/images/weixingEchartIcon1.png) no-repeat;
    }
  }
</style>