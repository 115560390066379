<template>
  <div class="weixing_right_content">
    <div class="weixing_right_box">
      <div class="top">
        <WexinTitle :cover="require('@/assets/images/weixingTitleIcon3.png')" :txt="'园区产业'"></WexinTitle>
        <WeixingTitleTwo :txt="'产业定位'"></WeixingTitleTwo>
        <IntroduceEcharts v-if="showFlag"></IntroduceEcharts>
        <!-- <div class="bottom_line">
          <img src="@/assets/images/weixingLine.png" alt="">
        </div> -->
      </div>
      <div class="bottom">
        <WeixingTitleTwo :txt="'产业发展'"></WeixingTitleTwo>
        <ul class="industry_list">
          <li v-for="(item, index) in industryList" :key="index">
            <WeixingTitleTwo :txt="item.title" :type="2"></WeixingTitleTwo>
            <p class="txt">{{ item.txt }}</p>
          </li>
        </ul>
        <div class="more_btn">
          <span @click="moreBtn">【查看更多】</span>
          <!-- {{$store.state.weixingHome.moreFlag}} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WexinTitle from '@/components/WeixingTitle.vue'
import WeixingTitleTwo from '@/components/WeixingTitleTwo.vue'
import IntroduceEcharts from '@/components/weixingHome/IntroduceEcharts.vue'
export default {
  data() {
    return {
      active: 0,
      industryList: [
        {
          title: '属地产业政策:',
          txt: `海淀区重点发展新一代信息技术、智能制造与高端装备、医药健康、空天、新材料等高精尖产业。`
        },
        {
          title: '相关企业需求:',
          txt: `一批即将进入产业化阶段的项目、新增落地的引进项目和产业孵化项目，迫切希望在海淀区寻找高挑高、大空间、大承重、动力能源完备的场所用于承接后续产业化。`
        },
        {
          title: '产业聚集区位:',
          txt: `北京卫星厂区位优势明显，项目地处航天科技聚集区，具有浓厚的产业聚集效应。 `
        }
      ],
      showFlag: false
    }
  },
  mounted() {
    this.showFun()
  },
  methods: {
    selBtn(index) {
      this.active = index
    },
    moreBtn() {
      this.$store.state.weixingHome.moreFlag = !this.$store.state.weixingHome.moreFlag
    },
    showFun() {
      setTimeout(() => {
        this.showFlag = true
      }, 800)
    }
  },
  components: {
    IntroduceEcharts,
    WexinTitle,
    WeixingTitleTwo
  }
}
</script>

<style scoped>

</style>